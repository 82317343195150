import React from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import "../../styles/Markdown.css";

const MarkdownRenderer = ({ markdownText }) => {
	const generateRandomId = () => {
		return `code-${Math.random().toString(36).substr(2, 9)}`;
	};

	const copyCode = (id) => {
		const codeElement = document.getElementById(id);
		const codeText = codeElement.innerText;

		navigator.clipboard
			.writeText(codeText)
			.then(() => {
				const button = codeElement.closest(".codehead").querySelector("button i");
				const textSpan = codeElement.closest(".codehead").querySelector("button span");

				button.className = "fas fa-check";
				textSpan.textContent = "tersalin!";
				button.parentElement.title = "tersalin!";

				setTimeout(() => {
					button.className = "fa-regular fa-copy";
					textSpan.textContent = "salin kode";
					button.parentElement.title = "salin kode";
				}, 2000);
			})
			.catch((err) => {
				console.error("Failed to copy text: ", err);
			});
	};

	return (
		<div className="markdown-container">
			<ReactMarkdown
				children={markdownText.trim()}
				remarkPlugins={[remarkGfm, remarkMath]}
				rehypePlugins={[rehypeKatex]}
				components={{
					code({ node, inline, className, children, ...props }) {
						const match = /language-(\w+)/.exec(className || "");
						const codeId = generateRandomId();
						return !inline && match ? (
							<div>
								<div className="codehead">
									<p>{match[1]}</p>
									<button
										onClick={() => copyCode(codeId)}
										id="copyBtn"
										title="salin kode"
									>
										<i className="fa-regular fa-copy"></i>
										<span>salin kode</span>
									</button>
								</div>
								<SyntaxHighlighter
									id={codeId}
									className="codes"
									style={oneDark}
									language={match[1]}
									PreTag="div"
									{...props}
								>
									{String(children).replace(/\n$/, "").trim()}
								</SyntaxHighlighter>
							</div>
						) : (
							<code className={className} {...props}>
								{children}
							</code>
						);
					}
				}}
			/>
		</div>
	);
};

export default MarkdownRenderer;