import React from "react";
import { motion } from "framer-motion";

const Footer = () => {
	const LOGO = process.env.PUBLIC_URL + "/yanzgpt.jpg";
	const footerLinks = {
		"Quick Links": [
			{ name: "Home", href: "#" },
			{ name: "Features", href: "#features" },
			{ name: "Models", href: "#models" },
			{ name: "About", href: "#about" }
		],
		Connect: [
			{ name: "WhatsApp", href: "https://wa.me/33525331017", icon: "fab fa-whatsapp" },
			{ name: "GitHub", href: "https://github.com/YanzBotz", icon: "fab fa-github" },
			{
				name: "Instagram",
				href: "https://www.instagram.com/yanzbotz_",
				icon: "fab fa-instagram"
			},
			{ name: "YouTube", href: "https://www.youtube.com/@YanzBotz", icon: "fab fa-youtube" }
		]
	};

	return (
		<footer className="bg-gradient-to-b from-[#0A0A1B] to-black relative overflow-hidden">
			<div className="container mx-auto px-4 py-12">
				<div className="grid grid-cols-1 md:grid-cols-4 gap-8">
					<div className="col-span-1 md:col-span-2">
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							whileInView={{ opacity: 1, y: 0 }}
							viewport={{ once: true }}
						>
							<div className="flex items-center mb-4">
								<img
									style={{
										width: "150px",
										height: "50px",
										objectFit: "cover",
										marginLeft: "-10px"
									}}
									src={LOGO}
									alt="Yanz-GPT"
									className="rounded-full mr-2"
								/>
							</div>
							<p className="text-gray-400 mb-6">
								Next generation AI assistant for accurate and relevant information.
							</p>
						</motion.div>
					</div>

					{Object.entries(footerLinks).map(([title, links], index) => (
						<motion.div
							key={title}
							initial={{ opacity: 0, y: 20 }}
							whileInView={{ opacity: 1, y: 0 }}
							viewport={{ once: true }}
							transition={{ delay: index * 0.1 }}
						>
							<h3 className="text-white font-semibold mb-4">{title}</h3>
							<ul className="space-y-2">
								{links.map((link, i) => (
									<li key={i}>
										<a
											href={link.href}
											className="text-gray-400 hover:text-white transition-colors flex items-center gap-2"
										>
											{link.icon && <i className={link.icon}></i>}
											{link.name}
										</a>
									</li>
								))}
							</ul>
						</motion.div>
					))}
				</div>

				<div className="border-t border-gray-800 mt-12 pt-8 text-center">
					<p className="text-gray-400">© 2024 Yanz-GPT. All rights reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
