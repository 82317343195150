import React, { useState, useEffect, useRef } from "react";
import { sendMessage, uploadImages, checkPrompt } from "./api";
import { ChatMessage } from "./ui/ChatMessage";
import { SkeletonLoader, ImageLoader, DefaultLoader } from "./ui/SkeletonLoader";
import { ChatHeader } from "./ui/ChatHeader";
import { ChatInput } from "./ui/ChatInput";
import { Sidebar } from "./ui/Sidebar";

import FormData from "form-data";

import AnimatedBackground from "./ui/AnimatedBackground";
import Qbox from "./ui/Qbox";

const ChatApp = () => {
	const [messages, setMessages] = useState([]);
	const [qboxShow, setQboxShow] = useState(true);
	const [imageUrl, setImageUrl] = useState(null);
	const [processMsg, setProcessMsg] = useState(false);
	const [singleImage, setSingleImage] = useState(true);
	const [loadingType, setLoadingType] = useState("default");
	const [loadingImageType, setLoadingImageType] = useState("single");

	const [inputMessage, setInputMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isPro, setIsPro] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [darkMode, setDarkMode] = useState(() => {
		const savedMode = localStorage.getItem("darkMode");
		return savedMode ? JSON.parse(savedMode) : false;
	});

	const chatBoxRef = useRef(null);
	const fileInputRef = useRef(null);

	useEffect(() => {
		const setVH = () => {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		};
		setVH();
		window.addEventListener("resize", setVH);
		window.addEventListener("orientationchange", setVH);
		return () => {
			window.removeEventListener("resize", setVH);
			window.removeEventListener("orientationchange", setVH);
		};
	}, []);

	useEffect(() => {
		localStorage.setItem("darkMode", JSON.stringify(darkMode));
		darkMode
			? document.documentElement.classList.add("dark")
			: document.documentElement.classList.remove("dark");
	}, [darkMode]);

	const generateTimestamp = () => {
		return new Date().toLocaleTimeString([], {
			hour: "2-digit",
			minute: "2-digit"
		});
	};

	const formatCitations = (text) => {
		const citationRegex = /\[(\d+)\]\s*(https?:\/\/[^\s]+)/g;
		const citationMap = {};
		let match;

		while ((match = citationRegex.exec(text)) !== null) {
			citationMap[match[1]] = match[2];
		}

		let mainText = text.split("Citations:")[0];
		mainText = mainText.replace(
			/\[(\d+)\]/g,
			(match, number) => `[[${number}]](${citationMap[number]})`
		);

		return mainText.trim();
	};

	const handleSendMessage = async () => {
		if (!inputMessage.trim() && !selectedFile) return;

		let userMessage;

		if (imageUrl !== null) {
			userMessage = {
				type: "user",
				content: {
					text: inputMessage,
					image: imageUrl
				},
				timestamp: generateTimestamp()
			};
		} else {
			userMessage = {
				type: "user",
				content: inputMessage,
				timestamp: generateTimestamp()
			};
		}

		setMessages((prev) => [...prev, userMessage]);
		setImageUrl(null);
		setProcessMsg(true);
		setIsLoading(true);
		setInputMessage("");
		setSelectedFile(null);
		let checkedPrompt = await checkPrompt(inputMessage);
		if (checkedPrompt) {
			console.log(checkedPrompt);
			setLoadingType(checkedPrompt?.cmd);
			setLoadingImageType("multi");
		}

		try {
			const messageHistory = messages.map((msg) => {
				let message = {
					role: msg.type === "user" ? "user" : "assistant",
					content: msg.content
				};
				return message;
			});

			let newMessage = {
				role: "user",
				content: inputMessage
			};

			messageHistory.push(newMessage);

			let response = await sendMessage(messageHistory, imageUrl, isPro);

			setMessages((prev) => [
				...prev,
				{
					type: "bot",
					image: response?.image,
					content: formatCitations(response.content),
					timestamp: generateTimestamp()
				}
			]);
		} catch (error) {
			setMessages((prev) => [
				...prev,
				{
					type: "bot",
					content: "Maaf, terjadi kesalahan dalam koneksi. Silakan coba lagi.",
					timestamp: generateTimestamp(),
					isError: true
				}
			]);
		} finally {
			setIsLoading(false);
			setLoadingType("default");
		}
	};

	const handleFileSelect = async (event) => {
		const file = event.target.files[0];

		if (file) {
			setSelectedFile(URL.createObjectURL(file));

			const result = await uploadImages(file);

			if (result && result.image) {
				setImageUrl(result.image);
			}
		}
	};

	return (
		<div className="h-screen w-full bg-slate-900 text-gray-200">
			<div
				className="relative h-full flex flex-col overflow-hidden"
				style={{ height: "calc(var(--vh, 1vh) * 100)" }}
			>
				<div className="fixed inset-0 pointer-events-none">
					<AnimatedBackground />
				</div>

				<div className="relative flex flex-col h-full z-10">
					<main className="flex-1 relative overflow-hidden pt-4">
						<div
							ref={chatBoxRef}
							id="chatBox"
							className="pl-2 pr-2 pt-4 absolute inset-0 overflow-y-auto overflow-x-hidden scrollbar-thumb-gray-600 scrollbar-track-transparent pb-32"
						>
							{messages.map((message, index) => (
								<ChatMessage
									setProcessMsg={setProcessMsg}
									key={index}
									{...message}
								/>
							))}

							{qboxShow && <Qbox setInputMessage={setInputMessage} />}

							{isLoading &&
								(loadingType === "text_completion" ? (
									<SkeletonLoader />
								) : loadingType === "imaging" ? (
									<ImageLoader loadingType={loadingImageType} />
								) : loadingType === "searching" ? (
									<SkeletonLoader />
								) : (
									<DefaultLoader />
								))}
						</div>
					</main>

					<div className="absolute bottom-0 left-0 right-0">
						<ChatInput
							inputMessage={inputMessage}
							setInputMessage={setInputMessage}
							selectedFile={selectedFile}
							setSelectedFile={setSelectedFile}
							isPro={isPro}
							setIsPro={setIsPro}
							handleSendMessage={handleSendMessage}
							fileInputRef={fileInputRef}
							handleFileSelect={handleFileSelect}
							setQboxShow={setQboxShow}
							imageUrl={imageUrl}
							processMsg={processMsg}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChatApp;
