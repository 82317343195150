import React from "react";
import { motion } from "framer-motion";

const WhatsAppCTA = () => {
	const LOGO = process.env.PUBLIC_URL + "/yanzgpt.jpg";
	return (
		<section className="py-24 relative overflow-hidden bg-dark-500">
			{/* Background Effects */}
			<div className="absolute inset-0">
				<div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(42,74,106,0.08)_0%,transparent_60%)]"></div>
				<div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(16,185,129,0.05)_0%,transparent_60%)]"></div>
			</div>

			<div className="container mx-auto px-4 relative z-10">
				<div className="max-w-6xl mx-auto">
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						viewport={{ once: true }}
						className="relative rounded-2xl overflow-hidden"
					>
						<div className="bg-gradient-to-br from-accent-500/10 to-accent-600/10 backdrop-blur-sm border border-accent-500/10 p-8 md:p-12">
							<div className="grid md:grid-cols-2 gap-12 items-center">
								{/* Left Content */}
								<div className="space-y-6">
									<motion.h2
										initial={{ opacity: 0, y: 20 }}
										whileInView={{ opacity: 1, y: 0 }}
										viewport={{ once: true }}
										className="font-orbitron text-3xl md:text-4xl font-bold text-white/90"
									>
										Experience Yanz-GPT on WhatsApp
									</motion.h2>

									<motion.p
										initial={{ opacity: 0, y: 20 }}
										whileInView={{ opacity: 1, y: 0 }}
										viewport={{ once: true }}
										transition={{ delay: 0.1 }}
										className="text-white/70 text-lg"
									>
										Connect with our advanced AI assistant directly through
										WhatsApp. Get instant responses, intelligent interactions,
										and seamless support.
									</motion.p>

									<motion.div
										initial={{ opacity: 0, y: 20 }}
										whileInView={{ opacity: 1, y: 0 }}
										viewport={{ once: true }}
										transition={{ delay: 0.2 }}
										className="space-y-4"
									>
										{[
											"24/7 Instant Responses",
											"Natural Conversations",
											"Multi-language Support",
											"Secure & Private"
										].map((feature, index) => (
											<div
												key={index}
												className="flex items-center space-x-3"
											>
												<div className="w-1.5 h-1.5 rounded-full bg-accent-500/40"></div>
												<span className="text-white/70">{feature}</span>
											</div>
										))}
									</motion.div>
								</div>

								{/* Right Content */}
								<div className="relative">
									<motion.div
										initial={{ opacity: 0, scale: 0.95 }}
										whileInView={{ opacity: 1, scale: 1 }}
										viewport={{ once: true }}
										className="bg-dark-400/50 rounded-xl p-6 border border-accent-500/10"
									>
										<div className="aspect-video relative rounded-lg overflow-hidden bg-dark-300/50 mb-6">
											<img
												src={LOGO}
												alt="Yanz-GPT Preview"
												className="object-cover w-full h-full"
											/>
										</div>

										<motion.a
											href="https://wa.me/33525331017"
											target="_blank"
											rel="noopener noreferrer"
											whileHover={{ scale: 1.02 }}
											whileTap={{ scale: 0.98 }}
											className="flex items-center justify-center gap-3 w-full py-4 px-6 
                                bg-accent-500/20 hover:bg-accent-500/30 
                                backdrop-blur-sm rounded-lg text-white/90 
                                border border-accent-500/20 hover:border-accent-500/30
                                transition-all duration-300 group"
										>
											<i className="fab fa-whatsapp text-xl group-hover:scale-110 transition-transform"></i>
											<span className="font-medium">Start Chatting</span>
										</motion.a>

										<p className="text-center text-white/50 text-sm mt-4">
											Available 24/7 for your queries
										</p>
									</motion.div>

									{/* Decorative Elements */}
									<div className="absolute -top-6 -left-6 w-24 h-24 bg-accent-500/5 rounded-full blur-2xl"></div>
									<div className="absolute -bottom-6 -right-6 w-32 h-32 bg-accent-500/5 rounded-full blur-2xl"></div>
								</div>
							</div>
						</div>
					</motion.div>

					{/* Additional Info */}
					<motion.div
						initial={{ opacity: 0 }}
						whileInView={{ opacity: 1 }}
						viewport={{ once: true }}
						className="text-center mt-8 text-white/50 text-sm"
					>
						Join thousands of users already experiencing Yanz-GPT on WhatsApp
					</motion.div>
				</div>
			</div>
		</section>
	);
};

export default WhatsAppCTA;
