// src/components/sections/LearnMore.jsx
import React from 'react';
import { motion } from 'framer-motion';

const LearnMore = () => {
  const sections = [
    {
      title: "Model Architecture",
      icon: "fa-microchip",
      content: [
        {
          subtitle: "Revolution Model (25B)",
          description: "Our standard model optimized for quick responses and everyday tasks",
          features: [
            "8K context window",
            "Multi-language support",
            "Low latency responses",
            "Optimized for mobile devices"
          ]
        },
        {
          subtitle: "Legacy Model (72B)",
          description: "Advanced model for complex tasks and deep understanding",
          features: [
            "32K context window",
            "Enhanced reasoning capabilities",
            "Code generation & analysis",
            "Complex problem solving"
          ]
        }
      ],
      gradient: "from-accent-500/10 via-accent-400/10 to-accent-500/10"
    },
    {
      title: "Technical Specifications",
      icon: "fa-gear",
      content: [
        {
          subtitle: "System Requirements",
          features: [
            "Node.js environment",
            "Minimum 2GB RAM",
            "Support for modern browsers",
            "HTTPS enabled environment"
          ]
        },
        {
          subtitle: "API Integration",
          features: [
            "RESTful API endpoints",
            "WebSocket support",
            "OAuth2 authentication",
            "Rate limiting system"
          ]
        }
      ],
      gradient: "from-accent-400/10 via-accent-300/10 to-accent-400/10"
    },
    {
      title: "Security Features",
      icon: "fa-shield-halved",
      content: [
        {
          subtitle: "Data Protection",
          features: [
            "End-to-end encryption",
            "Data anonymization",
            "Secure token handling",
            "Regular security audits"
          ]
        },
        {
          subtitle: "Compliance",
          features: [
            "GDPR compliance",
            "Data privacy standards",
            "Regular penetration testing",
            "Security certifications"
          ]
        }
      ],
      gradient: "from-accent-500/10 via-accent-400/10 to-accent-500/10"
    },
    {
      title: "Use Cases",
      icon: "fa-lightbulb",
      content: [
        {
          subtitle: "Business Applications",
          features: [
            "Customer support automation",
            "Content generation",
            "Data analysis",
            "Process automation"
          ]
        },
        {
          subtitle: "Development",
          features: [
            "Code assistance",
            "API documentation",
            "Testing automation",
            "Development workflow"
          ]
        }
      ],
      gradient: "from-accent-400/10 via-accent-300/10 to-accent-400/10"
    }
  ];

  return (
    <section id="learn-more" className="py-24 relative overflow-hidden bg-dark-500">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_20%_20%,rgba(42,74,106,0.1)_0%,transparent_60%)]"></div>
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_80%_80%,rgba(106,42,106,0.05)_0%,transparent_60%)]"></div>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="font-orbitron text-4xl font-bold mb-6 text-white/90">
            Learn More About Yanz-GPT
          </h2>
          <p className="text-white/70 text-lg max-w-3xl mx-auto">
            Discover the technical excellence and innovative features that make Yanz-GPT
            a leading AI solution for modern needs
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {sections.map((section, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className={`bg-gradient-to-br ${section.gradient} p-8 rounded-xl
                         backdrop-blur-sm border border-accent-500/10
                         hover:border-accent-500/20 transition-all duration-300
                         group hover:shadow-subtle`}
            >
              <div className="flex items-center mb-6">
                <div className="w-12 h-12 flex items-center justify-center rounded-xl 
                               bg-accent-500/10 group-hover:bg-accent-500/20 transition-colors">
                  <i className={`fas ${section.icon} text-2xl text-white/70 
                               group-hover:text-white/90 transition-colors`}></i>
                </div>
                <h3 className="text-2xl font-bold text-white/90 ml-4">{section.title}</h3>
              </div>

              {section.content.map((item, idx) => (
                <div key={idx} className="mb-6 last:mb-0">
                  <h4 className="text-lg font-semibold text-white/80 mb-3">{item.subtitle}</h4>
                  {item.description && (
                    <p className="text-white/70 mb-3">{item.description}</p>
                  )}
                  <ul className="space-y-2">
                    {item.features.map((feature, fidx) => (
                      <motion.li
                        key={fidx}
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ delay: (index * 0.1) + (fidx * 0.05) }}
                        className="flex items-center text-white/70"
                      >
                        <span className="w-1.5 h-1.5 rounded-full bg-accent-500/40 mr-3"></span>
                        {feature}
                      </motion.li>
                    ))}
                  </ul>
                </div>
              ))}
            </motion.div>
          ))}
        </div>

        {/* Call to Action */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="text-center mt-16"
        >
          <p className="text-white/70 mb-8 max-w-2xl mx-auto">
            Ready to experience the power of advanced AI? Start using Yanz-GPT today
            and discover how it can transform your workflow.
          </p>
          <motion.a
            href="#get-started"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="inline-flex items-center px-8 py-4 bg-accent-500/20 
                     hover:bg-accent-500/30 rounded-lg text-white/90 font-medium
                     border border-accent-500/20 hover:border-accent-500/30
                     transition-all duration-300"
          >
            <i className="fas fa-rocket mr-2"></i>
            Get Started Now
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default LearnMore;