import React, { useEffect } from "react";
import "../../styles/Qbox.css";

import combinedQuestions from "../../utils/Qbox";

const Qbox = ({ setInputMessage }) => {
	useEffect(() => {
		populateFields();
		autoScroll();
		const randomQuestionElement = document.querySelector(".random-question");
		randomQuestionElement.addEventListener("scroll", infiniteScroll);
		return () => randomQuestionElement.removeEventListener("scroll", infiniteScroll);
	}, []);

	const populateFields = () => {
		const fields = document.querySelectorAll(".field > div");
		fields.forEach((field) => {
			const shuffledQuestions = [...combinedQuestions].sort(() => 0.5 - Math.random());
			const subset = shuffledQuestions.slice(0, 1100);

			subset.forEach((question) => {
				const span = document.createElement("span");
				span.classList.add("question-item");
				span.onclick = () => setInputMessage(question);
				span.innerText = question;
				field.appendChild(span);
			});
		});
	};

	const autoScroll = () => {
		const container = document.querySelector(".random-question");
		container.scrollLeft = 30;

		setInterval(() => {
			container.scrollLeft += 1;
			if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
				container.scrollLeft = 0;
			}
		}, 60);
	};

	const infiniteScroll = () => {
		const container = document.querySelector(".random-question");
		if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
			container.scrollLeft = 0;
		}
	};

	return (
		<div id="Qbox" className="question-box">
			<img id="QLogo" src={process.env.PUBLIC_URL + "/yanz.png"} alt="logo" width="100" />
			<h2>Ask Yanz-GPT Anything</h2>
			<div id="randomQ" className="random-question">
				<div className="field">
					<div className="st"></div>
					<div className="nd"></div>
					<div className="th"></div>
				</div>
			</div>
		</div>
	);
};

export default Qbox;
