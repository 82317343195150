import React from 'react';
import { motion } from 'framer-motion';

const Models = () => {
  const models = [
    {
      name: "yanzgpt-revolution-25b-v3.0",
      description: "Our standard model designed for quick responses and efficient processing",
      features: [
        { name: "Fast Response Time", value: "~2 seconds" },
        { name: "Context Window", value: "8K tokens" },
        { name: "Language Support", value: "10+ languages" },
        { name: "Updates", value: "Monthly" }
      ],
      gradient: "from-accent-500/10 to-accent-600/10"
    },
    {
      name: "yanzgpt-legacy-72b-v3.0",
      description: "Advanced model with deeper processing capabilities for complex interactions",
      features: [
        { name: "Response Time", value: "~3-4 seconds" },
        { name: "Context Window", value: "32K tokens" },
        { name: "Language Support", value: "20+ languages" },
        { name: "Updates", value: "Weekly" }
      ],
      gradient: "from-accent-400/20 to-accent-500/20",
      isPro: true
    }
  ];

  return (
    <section className="py-24 relative overflow-hidden bg-dark-500">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_0%_0%,rgba(42,74,106,0.1)_0%,transparent_50%)]"></div>
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_100%_100%,rgba(106,42,106,0.05)_0%,transparent_50%)]"></div>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="font-orbitron text-4xl font-bold mb-6 text-white/90">
            Choose Your Model
          </h2>
          <p className="text-white/70 text-lg max-w-2xl mx-auto">
            Select the perfect AI model that matches your needs and requirements
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {models.map((model, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              whileHover={{ scale: 1.02 }}
              className={`relative p-8 rounded-xl bg-gradient-to-br ${model.gradient}
                         backdrop-blur-sm border border-accent-500/10 hover:border-accent-500/20
                         transition-all duration-300 group hover:shadow-subtle`}
            >
              {/* Pro Badge */}
              {model.isPro && (
                <div className="absolute -top-4 -right-4">
                  <span className="px-4 py-2 bg-accent-500/20 rounded-full text-white/90 text-sm font-medium
                                 border border-accent-500/20 backdrop-blur-sm">
                    PRO
                  </span>
                </div>
              )}

              {/* Model Name */}
              <h3 className="text-xl font-bold text-white/90 mb-4 font-orbitron">
                {model.name}
              </h3>

              {/* Description */}
              <p className="text-white/70 mb-8">
                {model.description}
              </p>

              {/* Features */}
              <div className="space-y-4">
                {model.features.map((feature, i) => (
                  <div 
                    key={i}
                    className="flex justify-between items-center py-2 border-b border-accent-500/10
                               group-hover:border-accent-500/20 transition-colors"
                  >
                    <span className="text-white/70">{feature.name}</span>
                    <span className="text-white/90 font-medium">{feature.value}</span>
                  </div>
                ))}
              </div>

              {/* Action Button */}
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`mt-8 w-full py-3 px-6 rounded-lg text-white/90 font-medium
                           ${model.isPro ? 
                             'bg-accent-500/20 hover:bg-accent-500/30 border border-accent-500/20' : 
                             'bg-dark-400/50 hover:bg-dark-400/70 border border-accent-500/10'}
                           transition-all duration-300`}
              >
                {model.isPro ? 'Upgrade to Pro' : 'Get Started'}
              </motion.button>
            </motion.div>
          ))}
        </div>

        {/* Additional Info */}
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="text-center text-white/60 mt-12 max-w-2xl mx-auto text-sm"
        >
          All models are continuously updated and improved. 
          Pro features include priority access, enhanced capabilities, and dedicated support.
        </motion.p>
      </div>
    </section>
  );
};

export default Models;