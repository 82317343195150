import React, { useEffect } from 'react';
import Navbar from './Navbar';
import Hero from './sections/Hero';
import Features from './sections/Features';
import Models from './sections/Models';
import About from './sections/About';
import Team from './sections/Team';
import WhatsAppCTA from './sections/WhatsAppCTA';
import LearnMore from './sections/LearnMore';
import Footer from './sections/Footer';
import ChatButton from './ChatButton';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 50
    });
  }, []);

  return (
    <div className="min-h-screen bg-[#0A0A1B] overflow-hidden">
      <div className="fixed inset-0 z-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_20%_30%,rgba(33,147,176,0.1)_0%,transparent_70%)]"></div>
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_80%_70%,rgba(255,0,132,0.1)_0%,transparent_70%)]"></div>
        <div className="absolute inset-0 bg-[linear-gradient(to_right,rgba(0,0,0,0.5)_0%,transparent_100%)]"></div>
      </div>
      <Navbar />
      <main className="relative z-10">
        <Hero />
        <Features />
        <Models />
        <About />
        <Team />
        <WhatsAppCTA />
        <LearnMore />
      </main>
      <Footer />
      <ChatButton />
    </div>
  );
};

export default Home;