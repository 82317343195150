import axios from "axios";
import "../Config.js";

const models = ["yanzgpt-revolution-25b-v3.0", "yanzgpt-legacy-72b-v3.0"];
const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const base64Data = reader.result.split(",")[1]; // Ambil data tanpa header
			resolve(base64Data);
		};
		reader.onerror = (error) => reject(error);
	});

export const sendMessage = async (messages, images, isPro = false) => {
	try {
		const response = await axios(global.API_URL + "/chat", {
			headers: {
				authorization: `Bearer ${global.API_KEY}`,
				"content-type": "application/json"
			},
			data: {
				messages,
				images: images,
				model: models[isPro ? 1 : 0]
			},
			method: "POST"
		});

		return response.data.choices[0].message;
	} catch (error) {
		console.error(`YanzGPT Error: ${error}`);
		throw error;
	}
};

export const uploadImages = async (img) => {
	try {
		const imageBase64 = await toBase64(img);
		let fileName = new Date().getTime();
		const payload = {
			imageBase64: imageBase64,
			filename: "upload-" + fileName // Nama file untuk disimpan di server
		};

		let response = await fetch(global.CDN_URL, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(payload)
		});

		if (!response.ok) {
			throw new Error("Upload failed");
		}

		let data = await response.json();
		return data;
	} catch (error) {
		console.error("Error:", error);
		return null;
	}
};

export const checkPrompt = async (prompt) => {
	try {
		const response = await axios(global.API_URL + "/check_prompt", {
			headers: {
				"content-type": "application/json"
			},
			data: {
				prompt
			},
			method: "POST"
		});
		return response?.data;
	} catch (e) {
		console.error(`YanzGPT Error: ${e.response?.data || e.message}`);
		return { cmd: "default" };
	}
};
